import { API_URL } from 'src/utils/constants'
import {
  APPROVED_WITHDRAW,
  BLOGS,
  BLOGS_ERROR,
  BLOGS_LOADING,
  COMMONFORM,
  COMMONFORM_ERROR,
  COMMONFORM_LOADING,
  FILTER_PROPERTY,
  FILTER_PROPERTY_ERROR,
  FORGOT_PASSWORD_STATE,
  INSTALLMENT_POSTS,
  INSTALLMENT_POSTS_ERROR,
  INSTALLMENT_POSTS_LOADING,
  INSURANCEFORM,
  INSURANCEFORM_ERROR,
  INSURANCEFORM_LOADING,
  LOAN,
  LOAN_ERROR,
  LOAN_LOADING,
  LOANFORM,
  LOANFORM_ERROR,
  LOANFORM_LOADING,
  LOANPOSTS,
  LOANPOSTS_LOADING,
  LOGOUT,
  PROPERTY,
  PROPERTY_ERROR,
  PROPERTY_LOADING,
  PROPERTYFORM,
  PROPERTYFORM_ERROR,
  PROPERTYFORM_LOADING,
  PUBLIC_BLOGS,
  PUBLIC_BLOGS_ERROR,
  PUBLIC_BLOGS_LOADING,
  PUBLIC_INSTALLMENT_POSTS,
  PUBLIC_INSTALLMENT_POSTS_ERROR,
  PUBLIC_INSTALLMENT_POSTS_LOADING,
  PUBLIC_LOANPOSTS,
  PUBLIC_LOANPOSTS_ERROR,
  PUBLIC_LOANPOSTS_LOADING,
  REFFERALS,
  REQUESTED_WITHDRAW,
  REQUESTEDBLOGS,
  REQUESTEDBLOGS_LOADING,
  SIGNUP,
  SIGNUP_LOADING,
  TOP_BAR_LOADING,
  UPDATE_USER_PROFILE,
  USERREWARD,
  USERREWARD_ERROR,
  USERREWARD_LOADING,
  USERS,
  USERSREWARD,
  USERSREWARD_ERROR,
  USERSREWARD_LOADING,
} from './constants'
// import auth from 'src/utils/firebase'
import { persistor } from './index'
import toast from 'react-hot-toast'
// import sendNotification from 'src/utils/hooks'

export const signUp = (data, navigate) => async (dispatch) => {
  dispatch({
    type: SIGNUP_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/auth/registration/sign-up`, requestOptions)
    console.log('reponse form signup', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch({
        type: SIGNUP_LOADING,
        payload: true,
      })
      toast.success('Otp is send to your email')
      setTimeout(() => {
        navigate(`/verifyuser?email=${data.email}`)
      }, 300)
    }
    if (response.status === 404) {
      response = await response.json()
      toast.error(response.message)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error(response.message)
    }
    dispatch({
      type: SIGNUP_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in signup ', error)
    dispatch({
      type: SIGNUP_LOADING,
      payload: false,
    })
  }
}
export const VerifyUser = (data, navigate) => async (dispatch) => {
  dispatch({
    type: SIGNUP_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/auth/verify`, requestOptions)
    console.log('reponse form signup', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch({
        type: SIGNUP_LOADING,
        payload: true,
      })
      dispatch({
        type: SIGNUP,
        payload: response,
      })
      setTimeout(() => {
        navigate('/login')
      }, 200)
    }
    if (response.status === 404) {
      response = await response.json()
      toast.error(response.message)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error(response.message)
    }
    dispatch({
      type: SIGNUP_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in signup ', error)
    dispatch({
      type: SIGNUP_LOADING,
      payload: false,
    })
  }
}
export const SignIn = (data, navigate) => async (dispatch) => {
  dispatch({
    type: SIGNUP_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/auth/login`, requestOptions)
    console.log('reponse form signup', response)
    if (response.status === 200) {
      response = await response.json()

      console.log('reponse->>', response)
      dispatch({
        type: SIGNUP_LOADING,
        payload: true,
      })
      dispatch({
        type: SIGNUP,
        payload: response,
      })
      console.log('response.user.userType', response)
      if (response.user.userType === 'admin') {
        navigate('/dashboard')
      } else if (response.user && response.user.userType === 'partner') {
        navigate('/profile')
      } else {
        navigate('/profile')
      }
    } else if (response.status === 401) {
      response = await response.json()

      toast.error(response.error.passwordError)
    } else if (response.status === 404) {
      response = await response.json()

      toast.error(response.error.numberError)
    } else if (response.status === 500) {
      response = await response.json()

      toast.error(response.error.numberError)
    }
    console.log('hi this is signin', response)
    dispatch({
      type: SIGNUP_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in signup ', error)
    toast.error(error.numberError ? error.numberError : 'something went wrong')
    dispatch({
      type: SIGNUP_LOADING,
      payload: false,
    })
  }
}
export const ForgotPasswordEmail = (data, navigate, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/auth/forgotpassword`, requestOptions)
    console.log('reponse form signup', response)
    if (response.status === 200) {
      response = await response.json()

      console.log('reponse->>', response)
      dispatch({
        type: FORGOT_PASSWORD_STATE,
        payload: { email: response.data.email },
      })
      setLoading(false)

      toast.success('Otp is send to your email')
      setTimeout(() => {
        navigate('/otpverification')
      }, 200)
      console.log('response.user.userType', response)
    } else if (response.status === 401) {
      response = await response.json()

      toast.error(response.error.passwordError)
    } else if (response.status === 404) {
      response = await response.json()

      toast.error(response.message ? response.message : 'User not found')
    }
    setLoading(false)
  } catch (error) {
    console.log('error in ForgotPasswordEmail ', error)
    toast.error('Something went wrong')
    setLoading(false)
  }
}
export const ForgotPasswordOtpVerification = (data, navigate, setLoading) => async (dispatch) => {
  try {
    setLoading(true)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/auth/verifyotp`, requestOptions)
    console.log('reponse form signup', response)
    if (response.status === 200) {
      response = await response.json()
      toast.success('Otp Verified successfully')

      console.log('reponse->>', response)
      dispatch({
        type: FORGOT_PASSWORD_STATE,
        payload: { ...response.data },
      })
      setLoading(false)

      setTimeout(() => {
        navigate('/resetpassword')
      }, 200)
      console.log('response.user.userType', response)
    } else if (response.status === 401) {
      response = await response.json()

      toast.error(response.error.passwordError)
    } else if (response.status === 404) {
      response = await response.json()

      toast.error(response.message ? response.message : 'User not found')
    }
    setLoading(false)
  } catch (error) {
    console.log('error in ForgotPasswordOtpVerification ', error)
    toast.error('Something went wrong')
    setLoading(false)
  }
}
export const ForgotPasswordResetPassword = (data, navigate, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/auth/resetpassword`, requestOptions)
    console.log('reponse form signup', response)
    if (response.status === 200) {
      response = await response.json()

      console.log('reponse->>', response)
      dispatch({
        type: FORGOT_PASSWORD_STATE,
        payload: null,
      })
      toast.success('Password reset successfully')
      setLoading(false)

      setTimeout(() => {
        navigate('/login')
      }, 200)
      console.log('response.user.userType', response)
    } else if (response.status === 401) {
      response = await response.json()

      toast.error(response.error.passwordError)
    } else if (response.status === 404) {
      response = await response.json()

      toast.error(response.message ? response.message : 'User not found')
    }
    setLoading(false)
  } catch (error) {
    console.log('error in ForgotPasswordResetPassword ', error)
    toast.error('Something went wrong')
    setLoading(false)
  }
}
export const UpdateUser = (id, data, token) => async (dispatch) => {
  dispatch({
    type: SIGNUP_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/auth/${id}`, requestOptions)
    console.log('reponse form signup', response)
    if (response.status === 200) {
      response = await response.json()

      console.log('reponse->>', response)
      dispatch({
        type: SIGNUP_LOADING,
        payload: true,
      })
      dispatch({
        type: SIGNUP,
        payload: response,
      })
      console.log('response.user.userType', response)
    } else if (response.status === 401) {
      response = await response.json()

      toast.error(response.error.passwordError)
    } else if (response.status === 404) {
      response = await response.json()

      toast.error('user not found')
    }
    console.log('hi this is signin', response)
    dispatch({
      type: SIGNUP_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in signup ', error)
    dispatch({
      type: SIGNUP_LOADING,
      payload: false,
    })
  }
}
export const getUserReward = (token) => async (dispatch) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/reward/user`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      return response.data
    }
  } catch (error) {
    console.log('error in get getUserReward ', error)
  }
}
export const getUserRewardById = (token, id) => async (dispatch) => {
  dispatch({
    type: USERREWARD_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/reward/userrewid/${id}`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch({
        type: USERREWARD,
        payload: response.data,
      })
      console.log('reponse->> from user rewards', response)
    }
    dispatch({
      type: USERREWARD_LOADING,
      payload: false,
    })
  } catch (error) {
    dispatch({
      type: USERREWARD_LOADING,
      payload: false,
    })
    dispatch({
      type: USERREWARD_ERROR,
      payload: error,
    })

    console.log('error in get property ', error)
  }
}
export const filterUserReward = (token, filter, setLoading) => async (dispatch) => {
  setLoading(true)
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(
      `${API_URL}/reward/filter/reward?userId=${filter.userId ? filter.userId : ''}&dateType=${
        filter.dateType ? filter.dateType : ''
      }`,
      requestOptions,
    )
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()

      return response.data
    }
    setLoading(false)
  } catch (error) {
    console.log('error in get property ', error)
    setLoading(false)
  }
}
export const getUsersReward = (token) => async (dispatch) => {
  dispatch({
    type: USERSREWARD_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/reward/`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch({
        type: USERSREWARD,
        payload: response.data,
      })
      console.log('reponse->> from user rewards', response)
    }
    dispatch({
      type: USERSREWARD_LOADING,
      payload: false,
    })
  } catch (error) {
    dispatch({
      type: USERSREWARD_LOADING,
      payload: false,
    })
    dispatch({
      type: USERSREWARD_ERROR,
      payload: error,
    })

    console.log('error in get property ', error)
  }
}
export const filterUsersReward = (token, data, setLoading) => async (dispatch) => {
  setLoading(true)
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/reward/?startDate=${data.startDate}`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      setLoading(false)
      return response.data
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    console.log('error in get property ', error)
    return []
  }
}
export const postProperty = (token, data, navigate, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/property`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      setTimeout(() => {
        navigate('/posts')
      }, 2000)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error(response.message)
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add property ', error)
    setLoading(false)
  }
}
export const getProperty = (token) => async (dispatch) => {
  dispatch({
    type: PROPERTY_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/property/get`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: PROPERTY,
        payload: response.data,
      })
      dispatch({
        type: PROPERTY_LOADING,
        payload: false,
      })
    }
    dispatch({
      type: PROPERTY_LOADING,
      payload: false,
    })
  } catch (error) {
    dispatch({
      type: PROPERTY_LOADING,
      payload: false,
    })
    dispatch({
      type: PROPERTY_ERROR,
      payload: error,
    })
    console.log('error in get property ', error)
  }
}
export const updateProperty = (token, id, data, navigate, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/property/${id}`, requestOptions)
    console.log('reponse form update property', response)
    if (response.status === 200) {
      response = await response.json()
      toast.success('data update successfully')
      console.log('reponse->>', response)
      setLoading(false)
      setTimeout(() => {
        navigate('/posts')
      }, 2000)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error(response.message)
    }
    setLoading(false)
  } catch (error) {
    console.log('error in get property ', error)
    setLoading(true)
  }
}
export const searhProperty = (token, filter, setLoading) => async (dispatch) => {
  try {
    dispatch({
      type: PROPERTY_LOADING,
      payload: true,
    })
    setLoading(true)
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(
      `${API_URL}/property/searchdata?filter=${filter.filter}`,
      requestOptions,
    )
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      setLoading(false)
      dispatch({
        type: PROPERTY,
        payload: response.data,
      })
      dispatch({
        type: PROPERTY_LOADING,
        payload: false,
      })
      console.log('reponse->>', response)
      return response.data
    } else {
      setLoading(false)
      return []
    }
  } catch (error) {
    console.log('error in get property ', error)
    dispatch({
      type: PROPERTY_LOADING,
      payload: false,
    })
    dispatch({
      type: PROPERTY_ERROR,
      payload: error,
    })
    setLoading(false)
    return []
  }
}
export const searhPropertyForPublic = (filter) => async (dispatch) => {
  dispatch({ type: FILTER_PROPERTY, payload: true })
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    let response = await fetch(
      `${API_URL}/property/public?city=${filter.city ? filter.city : ''}&location=${
        filter.location ? filter.location : ''
      }&propertyType=${filter.propertyType ? filter.propertyType : ''}&budgetMin=${
        filter.budgetMin > 0 ? filter.budgetMin : ''
      }&budgetMax=${filter.budgetMax > 0 ? filter.budgetMax : ''}&areaMax=${
        filter.areaMax > 0 ? filter.areaMax : ''
      }&areaMin=${filter.areaMin > 0 ? filter.areaMin : ''}&purpose=${
        filter.purpose ? filter.purpose : ''
      }`,
      requestOptions,
    )
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('res from search in public', response.data)
      dispatch({ type: FILTER_PROPERTY, payload: response.data })
    }
  } catch (error) {
    console.log('error in filter public property ', error)
    dispatch({ type: FILTER_PROPERTY, payload: false })
    dispatch({ type: FILTER_PROPERTY_ERROR, payload: error })
  }
}
export const deleteProperty = (token, id, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/property/${id}`, requestOptions)
    console.log('reponse form update blogs', response)
    if (response.status === 200) {
      setLoading(false)

      dispatch(getProperty(token))
      toast.success('Property deleted successfully')
    } else if (response.status === 401) {
      setLoading(false)

      toast.error('Only admin can delete the property')
    } else {
      setLoading(false)
    }
  } catch (error) {
    console.log('error in update blog ', error)
    setLoading(false)
  }
}
export const logout = (vals) => async (dispatch) => {
  try {
    await persistor.pause()
    persistor.flush().then(() => {
      return persistor.purge()
    })
    dispatch({
      type: LOGOUT,
    })
    // await auth.signOut()
    toast('Logged out successfully')
    vals?.navigate()
  } catch (error) {
    console.log('error in logout', error)
  }
}
export const requestWithDraw = (token, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/withdraw`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      toast.success(response.message)
    }

    if (response.status === 401) {
      response = await response.json()
      toast.error(response.message)
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add property ', error)
    setLoading(false)
  }
}
export const getWidthDraw = (token, filter, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(
      `${API_URL}/withdraw/filter?userId=${filter.userId ? filter.userId : ''}&status=${
        filter.status ? filter.status : ''
      }`,
      requestOptions,
    )
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      // toast.success(response.message)
      console.log('response widthdra')

      dispatch({
        type: REQUESTED_WITHDRAW,
        payload: response.data.filter((d) => d.status === 'Requested'),
      })
      dispatch({
        type: APPROVED_WITHDRAW,
        payload: response.data.filter((d) => d.status === 'Approved'),
      })
    }

    if (response.status === 401) {
      response = await response.json()
      toast.error(response.message)
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add property ', error)
    setLoading(false)
  }
}
export const getWidthDrawDashboard = (token, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/withdraw/dashboard`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      // toast.success(response.message)
      console.log('response widthdra')

      return response.data
    }

    if (response.status === 401) {
      response = await response.json()
      toast.error(response.message)
      return []
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add property ', error)
    setLoading(false)
  }
}
// Loan Form
export const updateWithDraw = (token, data, setLoading) => async (dispatch) => {
  console.log('data', data)

  try {
    setLoading(true)
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/withdraw/update/${data._id}`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      toast.success(response.message)
      dispatch(getWidthDraw(token, { userId: data.userId }, setLoading))
    }

    if (response.status === 401) {
      response = await response.json()
      toast.error(response.message)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    console.log('error in add property ', error)
  }
}
export const updateLoan = (token, id, data, navigate, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/loanform/update/${id}`, requestOptions)
    console.log('reponse form update loan', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      dispatch(getAllLoan(token, setLoading))
      navigate('/loanFormTable')
    }
    if (response.status === 400) {
      response = await response.json()
      console.log('response from the create blog ', response)
      // toast.error(response.message)
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add property ', error)
    setLoading(false)
  }
}
export const getBlogs = (token) => async (dispatch) => {
  dispatch({
    type: BLOGS_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/blog/all`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: BLOGS,
        payload: response.data,
      })
      dispatch({
        type: BLOGS_LOADING,
        payload: false,
      })
    }
    dispatch({
      type: BLOGS_LOADING,
      payload: false,
    })
  } catch (error) {
    dispatch({
      type: BLOGS_LOADING,
      payload: false,
    })
    dispatch({
      type: BLOGS_ERROR,
      payload: error,
    })
    console.log('error in get property ', error)
  }
}
export const getPublicBlogs = (data) => async (dispatch) => {
  dispatch({
    type: PUBLIC_BLOGS_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    let response = await fetch(
      `${API_URL}/blog/public?title=${data.title}&category=${data.category}`,
      requestOptions,
    )
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: PUBLIC_BLOGS,
        payload: response.data,
      })
      dispatch({
        type: PUBLIC_BLOGS_LOADING,
        payload: false,
      })
    }
    dispatch({
      type: PUBLIC_BLOGS_LOADING,
      payload: false,
    })
  } catch (error) {
    dispatch({
      type: PUBLIC_BLOGS_LOADING,
      payload: false,
    })
    dispatch({
      type: PUBLIC_BLOGS_ERROR,
      payload: error,
    })
    console.log('error in get property ', error)
  }
}
export const createBlog =
  (token, data, navigate, setLoading, setOpen, resetBlogState) => async (dispatch) => {
    try {
      setLoading(true)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
      }
      let response = await fetch(`${API_URL}/blog`, requestOptions)
      console.log('reponse form create blogs', response)
      if (response.status === 200) {
        setTimeout(() => {
          navigate('/blogs/all')
          resetBlogState()
          setOpen(false)
        }, 2000)
        dispatch(getBlogs(token))
      }
      if (response.status === 401) {
        response = await response.json()
        toast.error(response.message)
      }
      setLoading(false)
      setOpen(false)
      resetBlogState()
    } catch (error) {
      console.log('error in get property ', error)
      setLoading(false)
      setOpen(false)
      resetBlogState()
    }
  }
export const filterBlogs = (token, query) => async (dispatch) => {
  console.log('query', query)
  dispatch({
    type: BLOGS_LOADING,
    payload: true,
  })
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(
      `${API_URL}/blog/fil?status=${query?.status ? query?.status : ''}&category=${
        query?.category ? query?.category : ''
      }&search=${query?.search ? query?.search : ''}&blogsType=${
        query?.blogsType ? query?.blogsType : ''
      }`,
      requestOptions,
    )
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: BLOGS,
        payload: response.data,
      })
    }
    dispatch({
      type: BLOGS_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get property ', error)
    dispatch({
      type: BLOGS_ERROR,
      payload: error,
    })
    dispatch({
      type: BLOGS_LOADING,
      payload: false,
    })
  }
}
export const getBlog = (token, id, setLoading) => async (dispatch) => {
  setLoading(true)
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/blog/${id}`, requestOptions)
    console.log('reponse form add property', response)
    setLoading(false)

    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      return response.data
    } else if (response.status === 404) {
      response = await response.json()
      toast.error(response.message)
    }
  } catch (error) {
    console.log('error in get blog ', error)
    setLoading(false)
  }
}
export const updateBlog = (token, data, id, setLoading, setEdit, fcmtoken) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/blog/update/${id}`, requestOptions)
    console.log('reponse form update blogs', response)
    if (response.status === 200) {
      dispatch(getBlogs(token))
      dispatch(filterBlogs(token, { status: 'pending' }))
      setEdit(false)
      // if (data.status) {
      //   sendNotification('Blog status updated', `You blog is ${data.status} by the admin`, fcmtoken)
      // }
      toast.success('update the blog successfully')
    }
    setLoading(false)
  } catch (error) {
    console.log('error in update blog ', error)
    setLoading(false)
  }
}
export const setTopBarLoading = (loading) => async (dispatch) => {
  dispatch({ type: TOP_BAR_LOADING, payload: loading })
}
export const deleteBlog = (token, id, setLoading) => async (dispatch) => {
  try {
    toast.loading('Please wait....', { id: '12' })
    setLoading(true)
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/blog/delete/${id}`, requestOptions)
    console.log('reponse form update blogs', response)
    if (response.status === 200) {
      setLoading(false)

      dispatch(getBlogs(token))
      toast.success('blog deleted successfully', { id: '12' })
    } else if (response.status === 401) {
      setLoading(false)

      toast.error('Only admin can delete the blogs', { id: '12' })
    } else {
      setLoading(false)
    }
  } catch (error) {
    console.log('error in update blog ', error)
    setLoading(false)
  }
}
export const postLoan =
  (loanType, employmentType, inputData, navigate, setLoading) => async (dispatch) => {
    try {
      setLoading(true)
      const data = { loanType: loanType, employmentType: employmentType, ...inputData }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }
      let response = await fetch(`${API_URL}/loan`, requestOptions)
      console.log('reponse form add loan', response)
      if (response.status === 200) {
        response = await response.json()
        console.log('reponse->>', response)
        setLoading(false)
        setTimeout(() => {
          navigate('/loan')
        }, 2000)
      }
      if (response.status === 401) {
        response = await response.json()
        toast.error('invalid token')
      }
      setLoading(false)
    } catch (error) {
      console.log('error in add loan ', error)
      setLoading(false)
    }
  }
export const getAllLoan = (token, setLoading) => async (dispatch) => {
  try {
    dispatch({
      type: LOAN_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/loan/get`, requestOptions)
    console.log('reponse form add loan', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: LOAN_LOADING,
        payload: false,
      })
      dispatch({
        type: LOAN,
        payload: response.data,
      })
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: LOAN_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: LOAN_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in add loan ', error)
    setLoading(false)
  }
}

export const filterUser = (token, filterData, setLoading) => async (dispatch) => {
  console.log('filter data', filterData)

  try {
    setLoading(true)
    const requestOptions = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(
      `${API_URL}/auth/filter?userType=${
        filterData.userType ? filterData.userType : ''
      }&filterType=${filterData.filterType ? filterData.filterType : ''}&value=${
        filterData.value ? filterData.value : ''
      }`,
      requestOptions,
    )
    console.log('reponse form filter user', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      if (filterData.userType === 'partner') {
        dispatch({ type: USERS, payload: response.data })
      } else if (filterData.userType === 'agent') {
        console.log('ye tu agent hai', response.data)
        dispatch({ type: USERS, payload: response.data })
      } else if (filterData.userType === 'affiliate') {
        console.log('ye tu agent hai', response.data)
        dispatch({ type: USERS, payload: response.data })
      }
      return response.data
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('invalid token')
      return []
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add loan ', error)
    setLoading(false)
  }
}
export const deleteUser = (token, userType, id, setLoading, navigate) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/auth/delete/${id}`, requestOptions)
    console.log('reponse form update blogs', response)
    if (response.status === 200) {
      setLoading(false)

      navigate(`/user/${userType}`)
      dispatch(filterUser(token, { userType: userType }, setLoading))
      toast.success('user deleted successfully')
    } else if (response.status === 401) {
      setLoading(false)

      toast.error('Only admin can delete the user')
    } else {
      setLoading(false)
    }
  } catch (error) {
    console.log('error in update blog ', error)
    setLoading(false)
  }
}
export const updateUser =
  (token, data, id, setLoading, type, closeEditModal) => async (dispatch) => {
    try {
      setLoading(true)
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
      }
      let response = await fetch(`${API_URL}/auth/${id}`, requestOptions)
      console.log('reponse form update blogs', response)
      if (response.status === 200) {
        dispatch(getUserById(token, id, setLoading))
        closeEditModal()
        toast.success('update the user successfully')
      } else if (response.status === 404) {
        toast.error('User not found')
      } else if (response.status === 401) {
        toast.error('Only admin or user can update the user')
      }
      closeEditModal()
    } catch (error) {
      console.log('error in update blog ', error)
      setLoading(false)
      closeEditModal()
    }
  }
export const updateUserProfile =
  (token, data, id, setLoading, type, closeEditModal) => async (dispatch) => {
    try {
      setLoading(true)
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
      }
      let response = await fetch(`${API_URL}/auth/${id}`, requestOptions)
      console.log('reponse form update blogs', response)
      if (response.status === 200) {
        dispatch({ type: UPDATE_USER_PROFILE, payload: response.data })
        closeEditModal()
        toast.success('update the user successfully')
      } else if (response.status === 404) {
        toast.error('User not found')
      } else if (response.status === 401) {
        toast.error('Only admin or user can update the user')
      }
      closeEditModal()
    } catch (error) {
      console.log('error in update blog ', error)
      setLoading(false)
      closeEditModal()
    }
  }
export const getUserById = (token, id, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/auth/detail/${id}`, requestOptions)
    console.log('reponse form filter user', response)
    if (response.status === 200) {
      response = await response.json()
      setLoading(false)
      return response.data
    }
    if (response.status === 404) {
      setLoading(false)
      response = await response.json()
      toast.error('no record found')
      return null
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add loan ', error)
    setLoading(false)
  }
}
export const createRefferal = (token, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/refferal/create`, requestOptions)
    console.log('reponse form filter user', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch(getRefferals(token, setLoading))
      toast.success('Code created successfully')
      setLoading(false)
      return response.data
    }
    if (response.status === 401) {
      setLoading(false)
      response = await response.json()
      toast.error('Only partner can create')
      return null
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add loan ', error)
    setLoading(false)
  }
}
export const getRefferals = (token, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/refferal`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      dispatch({ type: REFFERALS, payload: response.data })
      return response.data
    } else {
      dispatch({ type: REFFERALS, payload: response.data })

      setLoading(false)
      return null
    }
  } catch (error) {
    setLoading(false)
    console.log('error in get refferals ', error)
    return null
  }
}
export const filterRefferals = (token, search, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/refferal?code=${search.code}`, requestOptions)
    console.log('reponse form add property', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      dispatch({ type: REFFERALS, payload: response.data })
      return response.data
    } else {
      dispatch({ type: REFFERALS, payload: response.data })

      setLoading(false)
      return null
    }
  } catch (error) {
    setLoading(false)
    console.log('error in get refferals ', error)
    return null
  }
}

//INSTALLMENT FORM ACTIONS
export const postInsuranceForm = (data, setLoading, navigate) => async (dispatch) => {
  try {
    setLoading(true)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/insuranceForm/`, requestOptions)
    console.log('reponse form add insurance', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      // setTimeout(() => {
      toast.success('Enquiry sent Successfully ')

      setLoading(false)
      navigate('/homescreen')
      // window.location.href = '/'
      console.log('Navigated')
      // }, 2000)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('invalid token')
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add insurance ', error)
    setLoading(false)
  }
}
export const getAllInsuranceForm = (token, setLoading, query) => async (dispatch) => {
  try {
    dispatch({
      type: INSURANCEFORM_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/insuranceForm/get?${query}`, requestOptions)
    console.log('reponse form get insurance form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: INSURANCEFORM_LOADING,
        payload: false,
      })
      dispatch({
        type: INSURANCEFORM,
        payload: response.data,
      })
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: INSURANCEFORM_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: INSURANCEFORM_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get insurance ', error)
    setLoading(false)
  }
}
export const deleteInsuranceForm = (token, id, setLoading) => async (dispatch) => {
  console.log('api being called')

  try {
    setLoading(true)

    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/insuranceForm/delete/${id}`, requestOptions)
    console.log('reponse form get LOAN form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      toast.success('delete successfully')
      dispatch(getAllInsuranceForm(token, setLoading, ''))
      return response.data
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      setLoading(false)
    }
    setLoading(false)
  } catch (error) {
    console.log('error in get LOAN form ', error)
    setLoading(false)
  }
}
export const updateInsuranceForm = (id, data, token, setLoading, navigate) => async (dispatch) => {
  try {
    setLoading(true)

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/insuranceForm/update/${id}`, requestOptions)
    console.log('reponse form get insurance form', response)
    if (response.status === 200) {
      toast.success('insurance form update successfully')
      dispatch(getAllInsuranceForm(token, setLoading))
      setTimeout(() => {
        navigate('/insurance')
      }, 300)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: INSURANCEFORM_ERROR,
        payload: 'Unauthorized',
      })
    }
    setLoading(false)
  } catch (error) {
    console.log('error in get insurance ', error)
    setLoading(false)
  }
}
//COMMON FORM ACTIONS
export const postCommonForm = (data, setLoading, navigate) => async (dispatch) => {
  try {
    setLoading(true)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/commonForm/`, requestOptions)
    console.log('reponse form add common form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      // setTimeout(() => {
      toast.success('Enquiry sent Successfully ')

      setLoading(false)
      navigate('/homescreen')
      // window.location.href = '/'
      console.log('Navigated')
      // }, 2000)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('invalid token')
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add common form ', error)
    setLoading(false)
  }
}
export const getAllCommonForm = (token, setLoading) => async (dispatch) => {
  try {
    dispatch({
      type: COMMONFORM_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/commonForm/get`, requestOptions)
    console.log('reponse form get common form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: COMMONFORM_LOADING,
        payload: false,
      })
      dispatch({
        type: COMMONFORM,
        payload: response.data,
      })
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: COMMONFORM_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: COMMONFORM_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get common form ', error)
    setLoading(false)
  }
}

//Property FORM ACTIONS
export const postPropertyForm = (data, setLoading, navigate) => async (dispatch) => {
  try {
    setLoading(true)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/propertyForm/`, requestOptions)
    console.log('reponse form add Property form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      toast.success('Enquiry sent Successfully ')

      // setTimeout(() => {
      navigate('/homescreen')
      // window.location.href = '/'
      console.log('Navigated')
      // }, 2000)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('invalid token')
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add Property form ', error)
    setLoading(false)
  }
}
export const getAllPropertyForm = (token, setLoading) => async (dispatch) => {
  try {
    dispatch({
      type: PROPERTYFORM_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/propertyForm/get`, requestOptions)
    console.log('reponse form get PROPERTY form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: PROPERTYFORM_LOADING,
        payload: false,
      })
      dispatch({
        type: PROPERTYFORM,
        payload: response.data,
      })
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: PROPERTYFORM_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: PROPERTYFORM_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get PROPERTY form ', error)
    setLoading(false)
  }
}

//LOAN FORM ACTIONS
export const postLoanForm = (data, setLoading, navigate) => async (dispatch) => {
  try {
    setLoading(true)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/loanForm/`, requestOptions)
    console.log('reponse form add Loan form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      toast.success('Enquiry sent Successfully ')

      // setTimeout(() => {
      navigate('/homescreen')
      // window.location.href = '/'
      console.log('Navigated')
      // }, 2000)
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('invalid token')
    }
    setLoading(false)
  } catch (error) {
    console.log('error in add Loan form ', error)
    setLoading(false)
  }
}
export const getAllLoanForm = (token, setLoading, query) => async (dispatch) => {
  console.log('api being called')

  try {
    dispatch({
      type: LOANFORM_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/loanForm/get?${query}`, requestOptions)
    console.log('reponse form get LOAN form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: LOANFORM_LOADING,
        payload: false,
      })
      dispatch({
        type: LOANFORM,
        payload: response.data,
      })
      return response.data
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: LOANFORM_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: LOANFORM_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get LOAN form ', error)
    setLoading(false)
  }
}
export const deleteLoanForm = (token, id, setLoading) => async (dispatch) => {
  console.log('api being called')

  try {
    setLoading(true)

    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/loanForm/delete/${id}`, requestOptions)
    console.log('reponse form get LOAN form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      setLoading(false)
      toast.success('delete successfully')
      dispatch(getAllLoanForm(token, setLoading, ''))
      return response.data
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      setLoading(false)
    }
    setLoading(false)
  } catch (error) {
    console.log('error in get LOAN form ', error)
    setLoading(false)
  }
}
export const createLoanPost = (token, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/loanpost`, requestOptions)
    console.log('reponse form filter user', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch(getAllLoanPosts(token, ''))
      toast.success('Loan post created successfully')
      setLoading(false)
      return true
    } else {
      setLoading(false)
      return false
    }
  } catch (error) {
    console.log('error in add loan ', error)
    setLoading(false)
  }
}
export const createInstallmentPost = (token, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/installmentplan`, requestOptions)
    console.log('reponse form filter user', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch(getAllLoanPosts(token, ''))
      toast.success('Instalment post created successfully')
      setLoading(false)
      return true
    } else {
      setLoading(false)
      return false
    }
  } catch (error) {
    console.log('error in add loan ', error)
    setLoading(false)
  }
}
export const updateLoanPost = (id, token, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/loanpost/update/${id}`, requestOptions)
    console.log('reponse form filter user', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch(getAllLoanPosts(token, ''))
      toast.success('Loan post created successfully')
      setLoading(false)
      return true
    } else {
      setLoading(false)
      return false
    }
  } catch (error) {
    console.log('error in add loan ', error)
    setLoading(false)
  }
}
export const updateInstallmentPost = (id, token, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(data),
    }
    let response = await fetch(`${API_URL}/installmentplan/update/${id}`, requestOptions)
    console.log('reponse form filter user', response)
    if (response.status === 200) {
      response = await response.json()
      dispatch(getAllLoanPosts(token, ''))
      toast.success('Loan post created successfully')
      setLoading(false)
      return true
    } else {
      setLoading(false)
      return false
    }
  } catch (error) {
    console.log('error in add loan ', error)
    setLoading(false)
  }
}
export const getAllLoanPosts = (token, query) => async (dispatch) => {
  console.log('api being called')

  try {
    dispatch({
      type: LOANPOSTS_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/loanpost/get?query=${query}`, requestOptions)
    console.log('reponse form get LOAN form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: LOANPOSTS_LOADING,
        payload: false,
      })
      dispatch({
        type: LOANPOSTS,
        payload: response.data,
      })
      return response.data
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: LOANFORM_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: LOANPOSTS_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get LOAN form ', error)
    dispatch({
      type: LOANPOSTS_LOADING,
      payload: false,
    })
  }
}
export const getAllInstallmentPosts = (token, query) => async (dispatch) => {
  console.log('api being called')

  try {
    dispatch({
      type: INSTALLMENT_POSTS_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    let response = await fetch(`${API_URL}/installmentplan/get?query=${query}`, requestOptions)
    console.log('reponse form get LOAN form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: INSTALLMENT_POSTS_LOADING,
        payload: false,
      })
      dispatch({
        type: INSTALLMENT_POSTS,
        payload: response.data,
      })
      return response.data
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: INSTALLMENT_POSTS_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: INSTALLMENT_POSTS_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get LOAN form ', error)
    dispatch({
      type: INSTALLMENT_POSTS_LOADING,
      payload: false,
    })
  }
}
export const getAllPublicLoanPosts = (query) => async (dispatch) => {
  console.log('api being called')

  try {
    dispatch({
      type: PUBLIC_LOANPOSTS_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
    }
    let response = await fetch(
      `${API_URL}/loanpost/get/public?tenure=${query.tenure ? query.tenure : ''}&loanAmount=${
        query.loanAmount ? query.loanAmount : ''
      }&title=${query.title ? query.title : ''}`,
      requestOptions,
    )
    console.log('reponse form get LOAN form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: PUBLIC_LOANPOSTS_LOADING,
        payload: false,
      })
      dispatch({
        type: PUBLIC_LOANPOSTS,
        payload: response.data,
      })
      return response.data
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: PUBLIC_LOANPOSTS_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: PUBLIC_LOANPOSTS_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get LOAN form ', error)
    dispatch({
      type: PUBLIC_LOANPOSTS_LOADING,
      payload: false,
    })
  }
}
// {
//   companyName: '',
//   category: '',
//   tenure: '',
//   price: 0,
//   downpayment: 0,
// }
export const getAllPublicInstallmentPosts = (query) => async (dispatch) => {
  console.log('api being called')

  try {
    dispatch({
      type: PUBLIC_INSTALLMENT_POSTS_LOADING,
      payload: true,
    })

    const requestOptions = {
      method: 'GET',
    }
    let response = await fetch(
      `${API_URL}/installmentplan/get/public?tenure=${query.tenure ? query.tenure : ''}&price=${
        query.price ? query.price : ''
      }&downpayment=${query.downpayment ? query.downpayment : ''}&companyName=${
        query.companyName ? query.companyName : ''
      }&category=${query.category ? query.category : ''}`,
      requestOptions,
    )
    console.log('reponse form get LOAN form', response)
    if (response.status === 200) {
      response = await response.json()
      console.log('reponse->>', response)
      dispatch({
        type: PUBLIC_INSTALLMENT_POSTS_LOADING,
        payload: false,
      })
      dispatch({
        type: PUBLIC_INSTALLMENT_POSTS,
        payload: response.data,
      })
      return response.data
    }
    if (response.status === 401) {
      response = await response.json()
      toast.error('Unauthorized')
      dispatch({
        type: PUBLIC_INSTALLMENT_POSTS_ERROR,
        payload: 'Unauthorized',
      })
    }
    dispatch({
      type: PUBLIC_INSTALLMENT_POSTS_LOADING,
      payload: false,
    })
  } catch (error) {
    console.log('error in get LOAN form ', error)
    dispatch({
      type: PUBLIC_INSTALLMENT_POSTS_LOADING,
      payload: false,
    })
  }
}
