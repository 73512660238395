import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { ForgotPasswordEmail, SignIn } from '../../../store/action'

import ButtonWithLoader from 'src/components/ButtonWithLoader'
const ForgotPassword = () => {
  const [loginDetail, setLoginDetails] = useState(null)
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.authDetails)
  const authLoading = useSelector((state) => state.auth.authLoading)
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
  }, [user])
  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }
  const handleInputValue = (e) => {
    const { name, value } = e.target
    setLoginDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }
  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const res = await dispatch(ForgotPasswordEmail(loginDetail, navigate, setLoading))
    } catch (error) {
      alert(error)
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h3>Reset Password</h3>

                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="jhonhoe@gmail.com"
                        autoComplete="email"
                        name="email"
                        onChange={(e) => handleInputValue(e)}
                      />
                    </CInputGroup>
                  </CForm>
                  <div className="d-flex justify-content-end">
                    <ButtonWithLoader
                      loading={authLoading}
                      type="submit"
                      color="primary"
                      className="px-4"
                    >
                      Reset
                    </ButtonWithLoader>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgotPassword
